module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-draw","options":{"strategy":"img"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/circleci/project/node_modules/@etclabscore/gatsby-theme-pristine/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/@etclabscore/gatsby-theme-pristine/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OpenRPC Website","short_name":"openrpc-site","start_url":"/","background_color":"#ffffff","theme_color":"#3f51b5","display":"minimal-ui","icon":"src/images/openrpc.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
